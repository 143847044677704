.icoStyleInsta {
  color: rgb(255, 255, 255);
  background-image: linear-gradient(to bottom left, rgb(148, 3, 252), rgb(255, 0, 64), rgb(252, 182, 3));
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 20%;

}

.icoStylefacebook {
  background-color: rgb(66, 103, 178);
  color: rgb(255, 255, 255);
  /* background-image: linear-gradient(to bottom left, rgb(148, 3, 252), rgb(255, 0, 64), rgb(252, 182, 3)); */
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 20%;

}

.footCol3 {
  text-align: right;
}

.footDivOut {
  position: relative;
  bottom: 3px;
  left: 0px;
  width: 100%;
  background-color: #254093;
  padding-top: 1px;
}

.footDivIn {
  padding: 20px;
  background:rgb(255, 255, 255);
}

.logoContainer {
  margin: 2%;
  max-width: 100%;
  width: 100%;
}

.contactButton {
  margin-bottom: 10px;
  color: #254093;
  border-color: #254093;
}

.contactButton:hover {
  background-color: #254093;
}

.socialLogo {
/* margin-left: 10%;
margin-right: 10%; */
padding-left: 13%;
padding-right:13%;
width: 10vh;
}