.outNavBar {
  /* background: linear-gradient(to right, #ffd700, rgb(250, 250, 250)); */
  background-color: #254093;
  padding-bottom: 2px;
}

.inNavBar {
  background: #ffffff;
}

.linkColor {
  color: #ffffff;
  font-weight: 400;
  /* padding-top: 10px; */
}

.linkColor:hover {
  color: #6885db;
}

.logoSize {
  width: 25vh;
}

.whatsApplogoSize {
  width: 5vh;
  display: inline-block;
}

.wWhatsApplogo {
  padding: 2%;
  width: 3.25vh;
  background-color: #25D366;
  border-radius: 20%;
  border-color: #25D366;
  display: inline-block;
}


.navInfoLink {
  /* margin: auto; */
  width: auto;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navInfo {
  /* margin: auto; */
  /* width: auto; */
  color: rgb(255, 78, 78);
  text-align: center;
  font-weight: bolder;
  padding-top: 10px;

}

.navButton {
  background-color: #254093;
  color: white;
  border-color: #254093;
}

.navButton:hover {
  background-color: #ffffff;
  border-color: #254093;
  color: #254093;

}