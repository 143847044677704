.videoContainer {
  width: 100%;
  height: 100%;
}

.videoElement {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.slideBoxwidth {
  margin-left: 5%;
  margin-right: 5%;
}

.videoContent {
  /* position: absolute; */
  width: 100%;
  height: 27%;
  top: 0;
  /* margin-top: 73vh; */
  margin-left: 0%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color:#254093;
  background-color: #DEE4F7;
  font-size: larger;
}

.videoContainerMobile {
  width: 100%;
  height: 90vh;
}
.videoContentMobile{
  position: absolute;
  top: 0;
  margin-top: 22vh;
  margin-left: 0%;
  padding: 5%;
  width: 100%;
  height: 78%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color:rgb(255, 255, 255);
  text-align: justify;
  background-color: rgba(0, 0, 0, 0.5);
}

.whyChoose {
  width: 100%;
  height: 20vh;
  font-size: 150%;
  margin-top: 1%;
  padding-top: 4%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.slideBoxDeck {
  width: 100%;
  height: 80vh;
}

.slideBoxDeckMobile {
  width: auto;
  margin-bottom: 1000px;

  /* height: 80vh; */
}

.slideBox{
  width: 100%;
  height: 33vh;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 5%;
  border-width: 5px;
  border-radius: 5%;
  border-color: #254093;
  background-color: #254093;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight:700;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-color: #254093;
  border-width: 4px;
  border-radius: 5%;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.slideBox:hover .overlay {
  height: 100%;
}

.overlayText {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color:#254093;

}

.contactContainer {
  width: 100%;
  height: 80vh;
}

.contactContainerMobile {
  width: auto;
  margin-top: 5%;
  padding-top: 5%;
}

.contactInfoBox {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
}

.contactInfoBoxMobile {
width: 100%;
margin: 10%;
padding: 5%;

}

.contactInfo {
  margin-top: 7%;
  width: auto;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  text-align: center;
}

.contactInfoMobile {
  margin-top: 7%;
  width: 100%;
  align-content: center;
  /* height: 25vh; */
  /* display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;*/
}

.contactHeading {
  font-size: large;
  font-weight: 500;
}

.directionsButton {
  margin-top: 10px;
  color: #254093;
  border-color: #254093;
}
.directionsButton:hover {
  background-color: #254093;
}

.chatButton {
  margin-top: 10px;
  color: #25D366;
  border-color: #25409300;
}
.chatButton:hover {
  background-color: #25D366;
  border-color: #25D366;
}

.whatsAppText {
  display: inline-block;
  font-weight: 600;
}

.landscapeForm {
  padding-right: 5%;
}

.reviewContainer {
  width: auto;
  background-size: cover;
  background-position: center;
  background-position-y: top;
}

.testimonialsTitle {
text-align: center;
font-size: 150%;
font-weight: bolder;
margin-top: 2%;
}

.carouselSlide {
  width: 100%;
  /* background-color: #0000001d; */
  align-items: center;

}

.carousel-control-next-icon{
  border-color: #254093;
}

.reviewCard {
  padding: 1%;
/* height: 60vh; */
/* min-height: 60vh; */
text-align: center;
display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}

.threeReviews {
margin-left: 5%;
margin-right: 5%;
}

.reviewRow {
margin: 2% 5%;
justify-content: center;
}

.reviewCol {
  margin: 1%;
  padding: 2%;
  /* margin-left: 5%;
margin-right: 5%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%; */
border-radius: 6px;
  background-color: #000000a1;
  color: #ffffff;
}

.review {
  height: 100%;
  width: 25%;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #00000060;
}

.reviewerLogo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
}

.nameColor1{
  background-color: #254093;
}

.nameColor2{
  background-color: #7728d7;
}

.nameColor3{
  background-color: #1a84ab;
}

.nameColor4{
  background-color: #9b004d;
}

.nameColor5{
  background-color: #16a803;
}

.reviewImage {
  width: 100%;
}

.reviewerName {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content:center;
  /* align-items: center; */
}

.ratingStar {
  width: 35px;
}

.reviewTitleBox {
  width: 95%;
  margin: auto;
  align-items: center;
}

.ratingBox {
  width: 95%;
  margin: auto;
  align-items: center;
}

.reviewText {
  width: 95%;
  margin: auto;
  align-items: center;
  text-align: left;
}

.container {
  margin: 0%;
  padding: 0%;
  max-width: 100%;
  overflow-x: hidden;

}

.gap {
  margin-top: 30px;
}

.containerBackgound {
  background-color: rgb(255, 255, 255);

  /* border-radius: 5%; */
}

.smallGap {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}


.textControl {
  text-align: justify;
}

.hoverEffect:hover {
  opacity: 0.3;
}

.card {

  position: relative;
  top: -50px;
  left: 0;
  width: 100%;
  margin-top: 5%;
  padding-top: 4%;
  padding-bottom: 0px;
  padding-left: 7%;
  padding-right: 7%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

.contactDiagonal {

  position: relative;
  top: -50px;
  left: 0;
  width: 100%;
  margin-top: 5%;
  padding-top: 5%;
  /* height: calc(100% + 500px); */
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  background-color: rgb(255, 255, 255);
}

.textLeft {
  padding-left: 5%;
  text-align: justify;
}

.geryColor {
  background-color: rgb(255, 255, 255);
}

.leftPadding {
  padding-left: 10%;
}

.justifyText {
  text-align: justify;
}

.topMargin {
  margin-top: 10%;
}

.leftRightPadding {
  padding-left: 5px;
  padding-right: 5px;
}

.homeImage {
  padding-top: 3%;
  width: 100%;
}

